import {Period} from '../types';

export abstract class AbstractURlFactory {
  abstract getSearchExposedKey():string;

  public findByDateGastoBetweenUrl(period:Period):URL {
    const url = new URL(`../api/${this.getSearchExposedKey()}/search/findByDateGastoBetween`,
        'https://cuentasv2.dgmatic.ca');

    const localUrl = new URL(`../api/${this.getSearchExposedKey()}/search/findByDateGastoBetween`,
        'http://localhost:8081');

    url.searchParams.append('start', period.start!.format('DD/MM/YY'));
    url.searchParams.append('end', period.end!.format('DD/MM/YY'));

    localUrl.searchParams.append('start', period.start!.format('DD/MM/YY'));
    localUrl.searchParams.append('end', period.end!.format('DD/MM/YY'));

    return url;
  }
}
