import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {Select, DatePicker, Space, message} from 'antd';
import {SearchResultTable} from './SearchResultTable';
import {generalSlice, getMessageStatus, getSearchResults, loadEntityByPeriodAsync} from '../../reducers/generalSlice';
import UrlFactory from '../../factories/urlFactory';
import moment, {Moment} from 'moment';
import {UrlApiUtils} from '../../utils/urlApiUtils';
import {MessageStatus, Period} from '../../types';

const {Option} = Select;
const {RangePicker, WeekPicker} = DatePicker;

declare type EventValue<Moment> = Moment | null;
declare type RangeValue<Moment> = [EventValue<Moment>, EventValue<Moment>] | null;

export function SearchCuentas() {
  const searchResults = useSelector(getSearchResults);
  const messageStatus = useSelector(getMessageStatus);
  const dispatch = useDispatch();

  const defaultSearchKey:string = 'Varios';
  const [searchKey, setSearchKey] = useState<string>('');
  const [period, setPeriod] = useState<Period>({
    start: moment().startOf('week'),
    end: moment().endOf('week')});

  useEffect(() => {
    if (searchKey) {
      const requestDataMap:Map<string, URL|string> = UrlApiUtils.getSearchData(searchKey, period);
      dispatch(loadEntityByPeriodAsync(
        requestDataMap.get(UrlApiUtils.EXPOSED_KEY) as string,
        requestDataMap.get(UrlApiUtils.URL_KEY) as URL));
    }
  }, [period, searchKey]);

  useEffect(() => {
    messageAlert();
  }, [messageStatus]);

  const messageAlert = () => {
    if (MessageStatus.SUCCESS === messageStatus) {
      message.success('success');
      dispatch(generalSlice.actions.setMessageStatus(MessageStatus.INVISIBLE));
    }
    if (MessageStatus.ERROR === messageStatus) {
      dispatch(generalSlice.actions.setMessageStatus(MessageStatus.INVISIBLE));
      message.error('error');
    }
  };

  const handleSearchKeyChange = (value:string) => {
    setSearchKey(value);
  };

  const handlePeriodChange = (value: Moment | null, dateString: string) => {
    const start:Moment = moment(value).startOf('week');
    const end:Moment = moment(value).endOf('week');
    setPeriod({start, end});
  };

  return (
    <>
      <Space direction={'horizontal'}>
        <Select style={{width: 120}} onChange={handleSearchKeyChange}>
          <Option value={UrlFactory.factory.comida.getSearchExposedKey()}>Comida</Option>
          <Option value={UrlFactory.factory.varios.getSearchExposedKey()}>Varios</Option>
          <Option value={UrlFactory.factory.carne.getSearchExposedKey()}>Carne</Option>
          <Option value={UrlFactory.factory.lacteoJugo.getSearchExposedKey()}>Lacteo y Jugos</Option>
          <Option value={UrlFactory.factory.merienda.getSearchExposedKey()}>Merienda</Option>
          <Option value={UrlFactory.factory.carros.getSearchExposedKey()}>Carros</Option>
          <Option value={UrlFactory.factory.oliviaJasper.getSearchExposedKey()}>Olivia y Emily</Option>
        </Select>
        <DatePicker picker="week" defaultValue={moment()} bordered={false} onChange={handlePeriodChange} />
      </Space>
      <Space>
        <SearchResultTable data={searchResults}/>
      </Space>
    </>
  );
}
