/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.34.976 on 2022-01-03 20:23:02.

export class HomeApplication {
}

export class WebSecurityConfigurerAdapter implements WebSecurityConfigurer<WebSecurity> {
}

export class WebSecurityConfig extends WebSecurityConfigurerAdapter {
    readonly objectPostProcessor?: ObjectPostProcessor<any>;
    readonly authenticationConfiguration?: AuthenticationConfiguration;
    readonly trustResolver?: AuthenticationTrustResolver;
    readonly contentNegotationStrategy?: ContentNegotiationStrategy;
    readonly applicationContext?: ApplicationContext;
}

export class TotalGastoController {
    readonly mesActual?: number;
}

export class TotalGastoFijoController {
    readonly mesActual?: number;
}

export class Cuenta {
    readonly id?: number;
    readonly monto?: number;
    readonly mensual?: boolean;
    readonly dateGasto?: DateAsString;
    readonly dateInserted?: DateAsString;
    readonly csfrToken?: string;
}

export class Carne extends Cuenta {
}

export class Carro extends Cuenta {
}

export class Comida extends Cuenta {
}

export class LacteoJugo extends Cuenta {
}

export class Merienda extends Cuenta {
}

export class OliviaJasper extends Cuenta {
}

export class TotalGastoFijoMesActual {
    readonly value?: number;
}

export class TotalGastoMesActual {
    readonly value?: number;
}

export class Varios extends Cuenta {
}

export class ApiError {
    readonly status?: HttpStatus;
    readonly timestamp?: DateAsString;
    readonly message?: string;
    readonly debugMessage?: string;
    readonly subErrors?: ApiSubError[];
}

export class ApiSubError {
}

export class ApiValidationError extends ApiSubError {
    readonly object?: string;
    readonly field?: string;
    readonly rejectedValue?: any;
    readonly message?: string;
}

export class ResponseEntityExceptionHandler {
}

export class RestExceptionHandler extends ResponseEntityExceptionHandler {
}

export interface AverageView {
    readonly average?: number;
}

export interface ComidaView extends CuentaView {
}

export interface CuentaView {
    readonly id?: number;
    readonly monto?: number;
    readonly mensual?: boolean;
    readonly dateGasto?: DateAsString;
    readonly dateInserted?: DateAsString;
}

export interface VariosView extends CuentaView {
}

export interface CarneJPARepository extends JpaRepository<Carne, number> {
}

export interface CarroJPARepository extends JpaRepository<Carro, number> {
}

export interface ComidaJPARepository extends JpaRepository<Comida, number> {
}

export interface LacteoJugoJPARepository extends JpaRepository<LacteoJugo, number> {
}

export interface MeriendaJPARepository extends JpaRepository<Merienda, number> {
}

export interface OliviaJasperJPARepository extends JpaRepository<OliviaJasper, number> {
}

export interface TotalGastoFijoJPARepository extends JpaRepository<TotalGastoFijoMesActual, number> {
}

export interface TotalGastoJPARepository extends JpaRepository<TotalGastoMesActual, number> {
}

export interface VariosJPARepository extends JpaRepository<Varios, number> {
}

export interface ObjectPostProcessor<T> {
}

export class AuthenticationConfiguration {
    readonly authenticationManager?: AuthenticationManager;
    readonly applicationContext?: ApplicationContext;
    readonly objectPostProcessor?: ObjectPostProcessor<any>;
    readonly globalAuthenticationConfigurers?: GlobalAuthenticationConfigurerAdapter[];
}

export interface AuthenticationTrustResolver {
}

export interface ContentNegotiationStrategy {
}

export interface ApplicationContext extends EnvironmentCapable, ListableBeanFactory, HierarchicalBeanFactory, MessageSource, ApplicationEventPublisher, ResourcePatternResolver {
    readonly parent?: ApplicationContext;
    readonly id?: string;
    readonly displayName?: string;
    readonly applicationName?: string;
    readonly autowireCapableBeanFactory?: AutowireCapableBeanFactory;
    readonly startupDate?: number;
}

export interface AuthenticationManager {
}

export class GlobalAuthenticationConfigurerAdapter implements SecurityConfigurer<AuthenticationManager, AuthenticationManagerBuilder> {
}

export interface AutowireCapableBeanFactory extends BeanFactory {
}

export interface Environment extends PropertyResolver {
    readonly activeProfiles?: string[];
    readonly defaultProfiles?: string[];
}

export interface BeanFactory {
}

export class ClassLoader {
}

export interface EnvironmentCapable {
    readonly environment?: Environment;
}

export interface ListableBeanFactory extends BeanFactory {
    readonly beanDefinitionCount?: number;
    readonly beanDefinitionNames?: string[];
}

export interface HierarchicalBeanFactory extends BeanFactory {
    readonly parentBeanFactory?: BeanFactory;
}

export interface MessageSource {
}

export interface ApplicationEventPublisher {
}

export interface ResourcePatternResolver extends ResourceLoader {
}

export interface JpaRepository<T, ID> extends PagingAndSortingRepository<T, ID>, QueryByExampleExecutor<T> {
}

export interface PropertyResolver {
}

export interface ResourceLoader {
    readonly classLoader?: ClassLoader;
}

export interface WebSecurityConfigurer<T> extends SecurityConfigurer<Filter, T> {
}

export class AbstractSecurityBuilder<O> implements SecurityBuilder<O> {
    readonly object?: O;
}

export class AbstractConfiguredSecurityBuilder<O, B> extends AbstractSecurityBuilder<O> {
    readonly sharedObjects?: { [index: string]: any };
    readonly orBuild?: O;
}

export class WebSecurity extends AbstractConfiguredSecurityBuilder<Filter, WebSecurity> implements SecurityBuilder<Filter>, ApplicationContextAware {
    readonly object?: Filter;
    readonly privilegeEvaluator?: WebInvocationPrivilegeEvaluator;
    readonly expressionHandler?: SecurityExpressionHandler<FilterInvocation>;
    readonly orBuild?: Filter;
    readonly applicationContext?: ApplicationContext;
}

export interface SecurityConfigurer<O, B> {
}

export class AuthenticationManagerBuilder extends AbstractConfiguredSecurityBuilder<AuthenticationManager, AuthenticationManagerBuilder> implements ProviderManagerBuilder<AuthenticationManagerBuilder> {
    readonly object?: AuthenticationManager;
    readonly defaultUserDetailsService?: UserDetailsService;
    readonly configured?: boolean;
    readonly orBuild?: AuthenticationManager;
}

export interface Filter {
}

export interface WebInvocationPrivilegeEvaluator {
}

export interface SecurityExpressionHandler<T> extends AopInfrastructureBean {
    readonly expressionParser?: ExpressionParser;
}

export class FilterInvocation {
    readonly chain?: FilterChain;
    readonly request?: HttpServletRequest;
    readonly response?: HttpServletResponse;
    readonly fullRequestUrl?: string;
    readonly httpRequest?: HttpServletRequest;
    readonly httpResponse?: HttpServletResponse;
    readonly requestUrl?: string;
}

export interface ApplicationContextAware extends Aware {
}

export interface PagingAndSortingRepository<T, ID> extends CrudRepository<T, ID> {
}

export interface QueryByExampleExecutor<T> {
}

export interface UserDetailsService {
}

export interface ExpressionParser {
}

export interface AopInfrastructureBean {
}

export interface FilterChain {
}

export interface HttpServletRequest extends ServletRequest {
    readonly method?: string;
    readonly session?: HttpSession;
    readonly userPrincipal?: Principal;
    readonly httpServletMapping?: HttpServletMapping;
    readonly requestedSessionId?: string;
    readonly requestedSessionIdFromURL?: boolean;
    readonly requestedSessionIdValid?: boolean;
    readonly requestedSessionIdFromCookie?: boolean;
    /**
     * @deprecated
     */
    readonly requestedSessionIdFromUrl?: boolean;
    readonly trailerFieldsReady?: boolean;
    readonly requestURI?: string;
    readonly pathTranslated?: string;
    readonly servletPath?: string;
    readonly pathInfo?: string;
    readonly queryString?: string;
    readonly authType?: string;
    readonly trailerFields?: { [index: string]: string };
    readonly parts?: Part[];
    readonly cookies?: Cookie[];
    readonly requestURL?: StringBuffer;
    readonly contextPath?: string;
    readonly headerNames?: Enumeration<string>;
    readonly remoteUser?: string;
}

export interface HttpServletResponse extends ServletResponse {
    readonly status?: number;
    readonly trailerFields?: Supplier<{ [index: string]: string }>;
    readonly headerNames?: string[];
}

export interface SecurityBuilder<O> {
}

export interface Aware {
}

export interface ProviderManagerBuilder<B> extends SecurityBuilder<AuthenticationManager> {
}

export interface HttpSession {
    readonly id?: string;
    readonly creationTime?: number;
    readonly attributeNames?: Enumeration<string>;
    readonly maxInactiveInterval?: number;
    readonly lastAccessedTime?: number;
    /**
     * @deprecated
     */
    readonly sessionContext?: HttpSessionContext;
    /**
     * @deprecated
     */
    readonly valueNames?: string[];
    readonly new?: boolean;
    readonly servletContext?: ServletContext;
}

export interface Principal {
    readonly name?: string;
}

export interface HttpServletMapping {
    readonly pattern?: string;
    readonly mappingMatch?: MappingMatch;
    readonly matchValue?: string;
    readonly servletName?: string;
}

export interface Part {
    readonly name?: string;
    readonly size?: number;
    readonly inputStream?: any;
    readonly contentType?: string;
    readonly submittedFileName?: string;
    readonly headerNames?: string[];
}

export class Cookie implements Cloneable, Serializable {
    readonly name?: string;
    readonly value?: string;
    readonly version?: number;
    readonly comment?: string;
    readonly domain?: string;
    readonly maxAge?: number;
    readonly path?: string;
    readonly secure?: boolean;
    readonly httpOnly?: boolean;
}

export class AbstractStringBuilder implements Appendable, CharSequence {
    readonly empty?: boolean;
}

export class StringBuffer extends AbstractStringBuilder implements Serializable, Comparable<StringBuffer>, CharSequence {
    readonly length?: number;
}

export interface Enumeration<E> {
}

export class Locale implements Cloneable, Serializable {
}

export interface ServletContext {
    readonly classLoader?: ClassLoader;
    readonly majorVersion?: number;
    readonly minorVersion?: number;
    readonly attributeNames?: Enumeration<string>;
    readonly effectiveMajorVersion?: number;
    readonly effectiveMinorVersion?: number;
    readonly responseCharacterEncoding?: string;
    readonly effectiveSessionTrackingModes?: SessionTrackingMode[];
    readonly virtualServerName?: string;
    readonly sessionCookieConfig?: SessionCookieConfig;
    readonly jspConfigDescriptor?: JspConfigDescriptor;
    readonly servletRegistrations?: { [index: string]: ServletRegistration };
    readonly defaultSessionTrackingModes?: SessionTrackingMode[];
    readonly servletContextName?: string;
    readonly requestCharacterEncoding?: string;
    readonly filterRegistrations?: { [index: string]: FilterRegistration };
    /**
     * @deprecated
     */
    readonly servletNames?: Enumeration<string>;
    readonly serverInfo?: string;
    /**
     * @deprecated
     */
    readonly servlets?: Enumeration<Servlet>;
    readonly sessionTimeout?: number;
    readonly contextPath?: string;
    readonly initParameterNames?: Enumeration<string>;
}

export interface AsyncContext {
    readonly request?: ServletRequest;
    readonly timeout?: number;
    readonly response?: ServletResponse;
}

export interface ServletRequest {
    readonly scheme?: string;
    readonly inputStream?: any;
    readonly protocol?: string;
    readonly contentLength?: number;
    readonly locale?: Locale;
    readonly contentLengthLong?: number;
    readonly contentType?: string;
    readonly localName?: string;
    readonly attributeNames?: Enumeration<string>;
    readonly parameterMap?: { [index: string]: string[] };
    readonly reader?: any;
    readonly secure?: boolean;
    readonly parameterNames?: Enumeration<string>;
    readonly servletContext?: ServletContext;
    readonly serverName?: string;
    readonly asyncContext?: AsyncContext;
    readonly asyncSupported?: boolean;
    readonly dispatcherType?: DispatcherType;
    readonly serverPort?: number;
    readonly asyncStarted?: boolean;
    readonly remoteHost?: string;
    readonly remoteAddr?: string;
    readonly remotePort?: number;
    readonly localPort?: number;
    readonly locales?: Enumeration<Locale>;
    readonly localAddr?: string;
    readonly characterEncoding?: string;
}

export interface Supplier<T> {
}

export class OutputStream implements Closeable, Flushable {
}

export class ServletOutputStream extends OutputStream {
    readonly ready?: boolean;
}

export class Writer implements Appendable, Closeable, Flushable {
}

export class PrintWriter extends Writer {
}

export interface ServletResponse {
    readonly locale?: Locale;
    readonly contentType?: string;
    readonly outputStream?: ServletOutputStream;
    readonly writer?: PrintWriter;
    readonly committed?: boolean;
    readonly bufferSize?: number;
    readonly characterEncoding?: string;
}

export interface CrudRepository<T, ID> extends Repository<T, ID> {
}

/**
 * @deprecated
 */
export interface HttpSessionContext {
    /**
     * @deprecated
     */
    readonly ids?: Enumeration<string>;
}

export interface Cloneable {
}

export interface Serializable {
}

export interface CharSequence {
    readonly empty?: boolean;
}

export interface SessionCookieConfig {
    readonly name?: string;
    readonly path?: string;
    readonly comment?: string;
    readonly domain?: string;
    readonly maxAge?: number;
    readonly httpOnly?: boolean;
    readonly secure?: boolean;
}

export interface JspConfigDescriptor {
    readonly jspPropertyGroups?: JspPropertyGroupDescriptor[];
    readonly taglibs?: TaglibDescriptor[];
}

export interface ServletRegistration extends Registration {
    readonly runAsRole?: string;
    readonly mappings?: string[];
}

export interface FilterRegistration extends Registration {
    readonly servletNameMappings?: string[];
    readonly urlPatternMappings?: string[];
}

export interface Servlet {
    readonly servletConfig?: ServletConfig;
    readonly servletInfo?: string;
}

export interface Appendable {
}

export interface Comparable<T> {
}

export interface JspPropertyGroupDescriptor {
    readonly buffer?: string;
    readonly elIgnored?: string;
    readonly isXml?: string;
    readonly urlPatterns?: string[];
    readonly pageEncoding?: string;
    readonly includeCodas?: string[];
    readonly includePreludes?: string[];
    readonly scriptingInvalid?: string;
    readonly deferredSyntaxAllowedAsLiteral?: string;
    readonly trimDirectiveWhitespaces?: string;
    readonly errorOnUndeclaredNamespace?: string;
    readonly defaultContentType?: string;
}

export interface TaglibDescriptor {
    readonly taglibLocation?: string;
    readonly taglibURI?: string;
}

export interface Registration {
    readonly name?: string;
    readonly className?: string;
    readonly initParameters?: { [index: string]: string };
}

export interface ServletConfig {
    readonly servletName?: string;
    readonly servletContext?: ServletContext;
    readonly initParameterNames?: Enumeration<string>;
}

export interface Closeable extends AutoCloseable {
}

export interface Flushable {
}

export interface Repository<T, ID> {
}

export interface AutoCloseable {
}

export type DateAsString = string;

/**
 * Values:
 * - `CONTINUE`
 * - `SWITCHING_PROTOCOLS`
 * - `PROCESSING`
 * - `CHECKPOINT`
 * - `OK`
 * - `CREATED`
 * - `ACCEPTED`
 * - `NON_AUTHORITATIVE_INFORMATION`
 * - `NO_CONTENT`
 * - `RESET_CONTENT`
 * - `PARTIAL_CONTENT`
 * - `MULTI_STATUS`
 * - `ALREADY_REPORTED`
 * - `IM_USED`
 * - `MULTIPLE_CHOICES`
 * - `MOVED_PERMANENTLY`
 * - `FOUND`
 * - `MOVED_TEMPORARILY` - @deprecated
 * - `SEE_OTHER`
 * - `NOT_MODIFIED`
 * - `USE_PROXY` - @deprecated
 * - `TEMPORARY_REDIRECT`
 * - `PERMANENT_REDIRECT`
 * - `BAD_REQUEST`
 * - `UNAUTHORIZED`
 * - `PAYMENT_REQUIRED`
 * - `FORBIDDEN`
 * - `NOT_FOUND`
 * - `METHOD_NOT_ALLOWED`
 * - `NOT_ACCEPTABLE`
 * - `PROXY_AUTHENTICATION_REQUIRED`
 * - `REQUEST_TIMEOUT`
 * - `CONFLICT`
 * - `GONE`
 * - `LENGTH_REQUIRED`
 * - `PRECONDITION_FAILED`
 * - `PAYLOAD_TOO_LARGE`
 * - `REQUEST_ENTITY_TOO_LARGE` - @deprecated
 * - `URI_TOO_LONG`
 * - `REQUEST_URI_TOO_LONG` - @deprecated
 * - `UNSUPPORTED_MEDIA_TYPE`
 * - `REQUESTED_RANGE_NOT_SATISFIABLE`
 * - `EXPECTATION_FAILED`
 * - `I_AM_A_TEAPOT`
 * - `INSUFFICIENT_SPACE_ON_RESOURCE` - @deprecated
 * - `METHOD_FAILURE` - @deprecated
 * - `DESTINATION_LOCKED` - @deprecated
 * - `UNPROCESSABLE_ENTITY`
 * - `LOCKED`
 * - `FAILED_DEPENDENCY`
 * - `TOO_EARLY`
 * - `UPGRADE_REQUIRED`
 * - `PRECONDITION_REQUIRED`
 * - `TOO_MANY_REQUESTS`
 * - `REQUEST_HEADER_FIELDS_TOO_LARGE`
 * - `UNAVAILABLE_FOR_LEGAL_REASONS`
 * - `INTERNAL_SERVER_ERROR`
 * - `NOT_IMPLEMENTED`
 * - `BAD_GATEWAY`
 * - `SERVICE_UNAVAILABLE`
 * - `GATEWAY_TIMEOUT`
 * - `HTTP_VERSION_NOT_SUPPORTED`
 * - `VARIANT_ALSO_NEGOTIATES`
 * - `INSUFFICIENT_STORAGE`
 * - `LOOP_DETECTED`
 * - `BANDWIDTH_LIMIT_EXCEEDED`
 * - `NOT_EXTENDED`
 * - `NETWORK_AUTHENTICATION_REQUIRED`
 */
export enum HttpStatus {
    CONTINUE = "CONTINUE",
    SWITCHING_PROTOCOLS = "SWITCHING_PROTOCOLS",
    PROCESSING = "PROCESSING",
    CHECKPOINT = "CHECKPOINT",
    OK = "OK",
    CREATED = "CREATED",
    ACCEPTED = "ACCEPTED",
    NON_AUTHORITATIVE_INFORMATION = "NON_AUTHORITATIVE_INFORMATION",
    NO_CONTENT = "NO_CONTENT",
    RESET_CONTENT = "RESET_CONTENT",
    PARTIAL_CONTENT = "PARTIAL_CONTENT",
    MULTI_STATUS = "MULTI_STATUS",
    ALREADY_REPORTED = "ALREADY_REPORTED",
    IM_USED = "IM_USED",
    MULTIPLE_CHOICES = "MULTIPLE_CHOICES",
    MOVED_PERMANENTLY = "MOVED_PERMANENTLY",
    FOUND = "FOUND",
    /**
     * @deprecated
     */
    MOVED_TEMPORARILY = "MOVED_TEMPORARILY",
    SEE_OTHER = "SEE_OTHER",
    NOT_MODIFIED = "NOT_MODIFIED",
    /**
     * @deprecated
     */
    USE_PROXY = "USE_PROXY",
    TEMPORARY_REDIRECT = "TEMPORARY_REDIRECT",
    PERMANENT_REDIRECT = "PERMANENT_REDIRECT",
    BAD_REQUEST = "BAD_REQUEST",
    UNAUTHORIZED = "UNAUTHORIZED",
    PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
    FORBIDDEN = "FORBIDDEN",
    NOT_FOUND = "NOT_FOUND",
    METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED",
    NOT_ACCEPTABLE = "NOT_ACCEPTABLE",
    PROXY_AUTHENTICATION_REQUIRED = "PROXY_AUTHENTICATION_REQUIRED",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    CONFLICT = "CONFLICT",
    GONE = "GONE",
    LENGTH_REQUIRED = "LENGTH_REQUIRED",
    PRECONDITION_FAILED = "PRECONDITION_FAILED",
    PAYLOAD_TOO_LARGE = "PAYLOAD_TOO_LARGE",
    /**
     * @deprecated
     */
    REQUEST_ENTITY_TOO_LARGE = "REQUEST_ENTITY_TOO_LARGE",
    URI_TOO_LONG = "URI_TOO_LONG",
    /**
     * @deprecated
     */
    REQUEST_URI_TOO_LONG = "REQUEST_URI_TOO_LONG",
    UNSUPPORTED_MEDIA_TYPE = "UNSUPPORTED_MEDIA_TYPE",
    REQUESTED_RANGE_NOT_SATISFIABLE = "REQUESTED_RANGE_NOT_SATISFIABLE",
    EXPECTATION_FAILED = "EXPECTATION_FAILED",
    I_AM_A_TEAPOT = "I_AM_A_TEAPOT",
    /**
     * @deprecated
     */
    INSUFFICIENT_SPACE_ON_RESOURCE = "INSUFFICIENT_SPACE_ON_RESOURCE",
    /**
     * @deprecated
     */
    METHOD_FAILURE = "METHOD_FAILURE",
    /**
     * @deprecated
     */
    DESTINATION_LOCKED = "DESTINATION_LOCKED",
    UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
    LOCKED = "LOCKED",
    FAILED_DEPENDENCY = "FAILED_DEPENDENCY",
    TOO_EARLY = "TOO_EARLY",
    UPGRADE_REQUIRED = "UPGRADE_REQUIRED",
    PRECONDITION_REQUIRED = "PRECONDITION_REQUIRED",
    TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
    REQUEST_HEADER_FIELDS_TOO_LARGE = "REQUEST_HEADER_FIELDS_TOO_LARGE",
    UNAVAILABLE_FOR_LEGAL_REASONS = "UNAVAILABLE_FOR_LEGAL_REASONS",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
    BAD_GATEWAY = "BAD_GATEWAY",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
    GATEWAY_TIMEOUT = "GATEWAY_TIMEOUT",
    HTTP_VERSION_NOT_SUPPORTED = "HTTP_VERSION_NOT_SUPPORTED",
    VARIANT_ALSO_NEGOTIATES = "VARIANT_ALSO_NEGOTIATES",
    INSUFFICIENT_STORAGE = "INSUFFICIENT_STORAGE",
    LOOP_DETECTED = "LOOP_DETECTED",
    BANDWIDTH_LIMIT_EXCEEDED = "BANDWIDTH_LIMIT_EXCEEDED",
    NOT_EXTENDED = "NOT_EXTENDED",
    NETWORK_AUTHENTICATION_REQUIRED = "NETWORK_AUTHENTICATION_REQUIRED",
}

export enum DispatcherType {
    FORWARD = "FORWARD",
    INCLUDE = "INCLUDE",
    REQUEST = "REQUEST",
    ASYNC = "ASYNC",
    ERROR = "ERROR",
}

export enum MappingMatch {
    CONTEXT_ROOT = "CONTEXT_ROOT",
    DEFAULT = "DEFAULT",
    EXACT = "EXACT",
    EXTENSION = "EXTENSION",
    PATH = "PATH",
}

export enum SessionTrackingMode {
    COOKIE = "COOKIE",
    URL = "URL",
    SSL = "SSL",
}
