import {ComidaUrlFactory} from './comidaUrlFactory';
import {VariosUrlFactory} from './variosUrlFactory';
import {AbstractURlFactory} from './abstractURlFactory';
import {MeriendaUrlFactory} from './meriendaUrlFactory';
import {LacteoJugoUrlFactory} from './lacteoJugoUrlFactory';
import {CarneUrlFactory} from './carneUrlFactory';
import {OliviaJasperUrlFactory} from './oliviaJasperUrlFactory';
import {CarrosUrlFactory} from './carrosUrlFactory';

class UrlFactory {
   public factory: Record<string, AbstractURlFactory>={};

   private urlFactories = [
     {name: 'comida', source: new ComidaUrlFactory()},
     {name: 'varios', source: new VariosUrlFactory()},
     {name: 'merienda', source: new MeriendaUrlFactory()},
     {name: 'lacteoJugo', source: new LacteoJugoUrlFactory()},
     {name: 'carne', source: new CarneUrlFactory()},
     {name: 'oliviaJasper', source: new OliviaJasperUrlFactory()},
     {name: 'carros', source: new CarrosUrlFactory()},
   ]

   constructor() {
     this.urlFactories.forEach((factory)=>this.factory[factory.name] = factory.source);
   }
}

export default new UrlFactory;


