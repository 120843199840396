import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../app/store';
import {CuentasApiServiceImpl} from '../service/impl/cuentasApiServiceImpl';
import {EntityRelConfig, GeneralState, MessageStatus} from '../types';
import {ApiError, Comida, Cuenta} from '../cuentas-api';


const initialState: GeneralState = {
  value: 0,
  searchResults: [],
  messageStatus: MessageStatus.INVISIBLE,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    pushGastoPrevisto: (state: GeneralState, action: PayloadAction<Cuenta>) => {
      if (action.payload.monto && state.value) {
        state.value += action.payload.monto;
      }
    },
    setCuentas: (state: GeneralState, action: PayloadAction<Cuenta & EntityRelConfig[]>) => {
      state.searchResults = action.payload;
    },
    setMessageStatus: (state: GeneralState, action: PayloadAction<MessageStatus>) => {
      state.messageStatus = action.payload;
    },
    updateCuentas: (state: GeneralState, action: PayloadAction<Cuenta & EntityRelConfig>) => {
      const searchResults: Cuenta & EntityRelConfig[] = state.searchResults.map<Cuenta & EntityRelConfig>(
          (cuenta:Cuenta & EntityRelConfig)=>
            cuenta._links.self.href===action.payload._links.self.href ? action.payload : cuenta);
      state.searchResults = searchResults;
    },
  },
});

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const loadEntityByPeriodAsync = (exposedKey:string, url:URL): AppThunk => (dispatch) => {
  CuentasApiServiceImpl.fetchApi<Cuenta & EntityRelConfig>(url, exposedKey)
      .subscribe({
        next: (response) => {
          if (!(response.result as ApiError).status) {
            if (Array.isArray(response.result)) {
              dispatch(generalSlice.actions.setCuentas(response.result));
            }
          } else if ((response.result as ApiError).status) {
            dispatch(generalSlice.actions.setMessageStatus(MessageStatus.ERROR));
          }
        },
        // @ts-ignore
        complete: () => {
          // dispatch(generalSlice.actions.setMessageStatus(MessageStatus.SUCCESS));
        },
        error: (err) => {
          dispatch(generalSlice.actions.setMessageStatus(MessageStatus.ERROR));
        },
      });
};

export const patchEntityAsync = (url:URL|string, entity:Cuenta, csrf:string): AppThunk => (dispatch) => {
  const entityToUpdate:Cuenta = {
    monto: entity.monto,
    dateGasto: entity.dateGasto,
    dateInserted: entity.dateInserted,
    mensual: entity.mensual,
  };
  CuentasApiServiceImpl.patchApi(url, entityToUpdate, csrf)
      .subscribe({
        next: (response) => {
          if (!(response.result instanceof ApiError)) {
          }
        },
        // @ts-ignore
        complete: () => {
          dispatch(generalSlice.actions.setMessageStatus(MessageStatus.SUCCESS));
        },
        error: (err) => {
          dispatch(generalSlice.actions.setMessageStatus(MessageStatus.ERROR));
        },
      });
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getSearchResults = (state: RootState):Cuenta & EntityRelConfig[] => {
  return state.general.searchResults;
};

export const getMessageStatus = (state: RootState):MessageStatus => {
  return state.general.messageStatus;
};

export default generalSlice.reducer;
