import {Period} from '../types';
import {Comida, Varios} from '../cuentas-api';
import UrlFactory from '../factories/urlFactory';

export class UrlApiUtils {
  public static EXPOSED_KEY = 'EXPOSED_KEY';
  public static URL_KEY = 'URL';

  public static getSearchData(searchKey:string, period:Period):Map<string, URL | string> {
    const result = new Map();
    if (UrlFactory.factory.comida.getSearchExposedKey()===searchKey) {
      result.set(this.EXPOSED_KEY, UrlFactory.factory.comida.getSearchExposedKey());
      result.set(this.URL_KEY, UrlFactory.factory.comida.findByDateGastoBetweenUrl(period));
      return result;
    } else if (UrlFactory.factory.varios.getSearchExposedKey() === searchKey) {
      result.set(this.EXPOSED_KEY, UrlFactory.factory.varios.getSearchExposedKey());
      result.set(this.URL_KEY, UrlFactory.factory.varios.findByDateGastoBetweenUrl(period));
      return result;
    } else if (UrlFactory.factory.carros.getSearchExposedKey() === searchKey) {
      result.set(this.EXPOSED_KEY, UrlFactory.factory.carros.getSearchExposedKey());
      result.set(this.URL_KEY, UrlFactory.factory.carros.findByDateGastoBetweenUrl(period));
      return result;
    } else if (UrlFactory.factory.lacteoJugo.getSearchExposedKey() === searchKey) {
      result.set(this.EXPOSED_KEY, UrlFactory.factory.lacteoJugo.getSearchExposedKey());
      result.set(this.URL_KEY, UrlFactory.factory.lacteoJugo.findByDateGastoBetweenUrl(period));
      return result;
    } else if (UrlFactory.factory.carne.getSearchExposedKey() === searchKey) {
      result.set(this.EXPOSED_KEY, UrlFactory.factory.carne.getSearchExposedKey());
      result.set(this.URL_KEY, UrlFactory.factory.carne.findByDateGastoBetweenUrl(period));
      return result;
    } else if (UrlFactory.factory.oliviaJasper.getSearchExposedKey() === searchKey) {
      result.set(this.EXPOSED_KEY, UrlFactory.factory.oliviaJasper.getSearchExposedKey());
      result.set(this.URL_KEY, UrlFactory.factory.oliviaJasper.findByDateGastoBetweenUrl(period));
      return result;
    } else if (UrlFactory.factory.merienda.getSearchExposedKey() === searchKey) {
      result.set(this.EXPOSED_KEY, UrlFactory.factory.merienda.getSearchExposedKey());
      result.set(this.URL_KEY, UrlFactory.factory.merienda.findByDateGastoBetweenUrl(period));
      return result;
    }
    return new Map();
  }
}
