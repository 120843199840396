import {ResponseBody} from '../../types';
import {from, Observable, of} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {catchError, switchMap} from 'rxjs/operators';
import {ApiError, Cuenta, HttpStatus} from '../../cuentas-api';


export class CuentasApiServiceImpl {
  public static fetchApi<T>(url:URL, exposedKeyRessource:string): Observable<ResponseBody<T>> {
    const headers: Headers = new Headers();
    headers.append('Authorization',
        `Basic ${btoa(unescape(encodeURIComponent('user' + ':' + 'pass')))}`);

    return fromFetch(url.href, {headers, method: 'GET'}).pipe(
        switchMap((response) => {
          // OK return data
          if (response.ok) {
            return from(response.json().then((json) => {
              /* const token:string = response.headers.get('Set-Cookie')!
                  .replace('XSRF-TOKEN=', '')
                  .replace('; Path=/;', '')
                  .replace(' Secure', '');*/

              const responseBody: T [] = json._embedded ? json._embedded[exposedKeyRessource] : json;
              /* const responseBodyWithToken: T[] = responseBody.map((response: T) => {
                return {...response, csfrToken: token};
              });*/
              // return new ResponseBody<T>(responseBodyWithToken);
              return new ResponseBody<T>(responseBody);
            }));
          } else {
            return of(new ResponseBody<T>({status: response.statusText, message: 'uncatched exception'} as ApiError));
          }
        }),
        catchError((err) => {
        // Network or other error, handle appropriately
          console.error(err);
          return of(new ResponseBody<T>(err));
        }),
    );
  }

  public static patchApi(url:URL | string, body:Cuenta, csrf:string): Observable<ResponseBody<HttpStatus>> {
    const headers: Headers = new Headers();
    headers.append('Authorization',
        `Basic ${btoa(unescape(encodeURIComponent('user' + ':' + 'pass')))}`);
    headers.append('X-XSRF-TOKEN', csrf);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept-Encoding', 'identity');

    return fromFetch(url instanceof URL ? url.href : url, {headers, method: 'PATCH', body: JSON.stringify(body)}).pipe(
        switchMap((response) => {
        // OK return data
          return from(response.json().then((json) => {
            return new ResponseBody<HttpStatus>(response.statusText as HttpStatus);
          }));
        }),
        catchError((err) => {
        // Network or other error, handle appropriately
          console.error(err);
          return of(new ResponseBody<HttpStatus>(err));
        }),
    );
  }
}
