import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {InputNumber, Space, Table, Button, DatePicker, Popover, message} from 'antd';
import {generalSlice, getMessageStatus, patchEntityAsync} from '../../reducers/generalSlice';
import {EntityRelConfig, MessageStatus, SearchResultTableProps} from '../../types';
import {useCookies} from 'react-cookie';
import {Cuenta, DateAsString} from '../../cuentas-api';
import moment, {Moment} from 'moment';


export function SearchResultTable(props:SearchResultTableProps) {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['XSRF-TOKEN']);
  const [isDateInsertedVisible, setDateInsertedVisible] = useState(false);

  const handleMontoChange = (record:Cuenta & EntityRelConfig)=> (monto:number) => {
    const updatedRecord:Cuenta & EntityRelConfig = {...record, monto};
    dispatch(generalSlice.actions.updateCuentas(updatedRecord));
  };

  const handleDateGastoChange = (record:Cuenta & EntityRelConfig)=> (value: Moment | null, dateString: string) => {
    const updatedRecord:Cuenta & EntityRelConfig = {...record, dateGasto: value!.format('YYYY-MM-DD')};
    dispatch(generalSlice.actions.updateCuentas(updatedRecord));
  };

  const handleSave = (record:Cuenta & EntityRelConfig)=> (event:any) => {
    dispatch(patchEntityAsync(record._links.self.href, record, cookies['XSRF-TOKEN']));
  };


  const handleRowAction = (text:any, record:Cuenta & EntityRelConfig):any=>{
    return ( <Space size="middle">
      <Button onClick={ handleSave(record)}>Save</Button>
    </Space>);
  };

  const handleRowMontoRender = (value:number, record:Cuenta & EntityRelConfig):any=>{
    return <InputNumber max={9999.99} addonAfter="$" value={value} onChange={handleMontoChange(record)} />;
  };

  const handleDateInsertedRender = (value:string, record:Cuenta & EntityRelConfig):any=>{
    return (
      <Popover
        content={<a onClick={() => setDateInsertedVisible(false)}>Close</a>}
        title={value}
        trigger="click"
        visible={isDateInsertedVisible}
        onVisibleChange={() => setDateInsertedVisible(true)}
      >
        <Button type="primary">show</Button>
      </Popover>
    );
  };

  const handleRowDateGastoRender = (value:DateAsString, record:Cuenta & EntityRelConfig):any=>{
    return <DatePicker format={'YYYY-MM-DD'}
      value={moment(value, 'YYYY-MM-DD')}
      onChange={handleDateGastoChange(record)} />;
  };

  const columnsResult = [
    {
      title: 'Monto',
      key: 'monto',
      dataIndex: 'monto',
      width: 120,
      render: handleRowMontoRender,
    },
    /*  {
       title: 'Date Inserted',
       dataIndex: 'dateInserted',
       key: 'dateInserted',
       width: 100,
       render: handleDateInsertedRender,
     },*/
    {
      title: 'Date Gasto',
      key: 'dateGasto',
      dataIndex: 'dateGasto',
      render: handleRowDateGastoRender,
    },
    {
      title: 'Action',
      key: 'action',
      render: handleRowAction,
    },
  ];

  return (
    <Table columns={columnsResult} dataSource={props.data}/>
  );
}
