import {Moment} from 'moment';
import {ApiError, Cuenta} from './cuentas-api';


export interface GeneralState {
  value: number;
  searchResults: Cuenta & EntityRelConfig[],
  messageStatus:MessageStatus
}

export enum MessageStatus {
  INVISIBLE='none',
  SUCCESS='success',
  ERROR='error'
}

export interface EntityRelConfig {
  readonly _links:{self:{href:string}}
}

export interface SearchResultTableProps {
  readonly data: Cuenta & EntityRelConfig[];
}

export interface WeekStatistic {
  readonly title: string
  readonly value: number
  readonly total: number
}

export interface Period {
  readonly start?: Moment;
  readonly end?: Moment;
}

// eslint-disable-next-line require-jsdoc
export class ResponseBody<T> {
  constructor(result: T[] | T | ApiError) {
    this.result = result;
  }
  readonly result?: T[] | T | ApiError;
}
