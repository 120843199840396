import React from 'react';
// @ts-ignore
import logo from './logo.svg';
import './App.css';
import {SearchCuentas} from './components/search/SearchCuentas';

// eslint-disable-next-line require-jsdoc
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <span>Seleccionar una semana a la vez solamente</span>
      </header>
      <SearchCuentas />
    </div>
  );
}

export default App;
